<template>
  <v-container class="config-container pa-0">
    <v-container class="container-chart pa-0">
      <ResultadoPorMes />
    </v-container>
    <v-container class="container-card">
      <v-card class="d-flex justify-space-around">
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="getHiddenValue != false ? 'R$ 1.079.587,00' : '-'"
          label="RECEITA"
        />
        <v-divider vertical class="divider-card"></v-divider>
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="'R$ 457.000,00'"
          label="SALÁRIOS"
        />
        <v-divider vertical class="divider-card"></v-divider>
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="'R$ 97.154,00'"
          label="COMISSÃO"
        />
        <v-divider vertical class="divider-card"></v-divider>
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="'R$ 560.742,00'"
          label="PPLR"
        />
      </v-card>
      <v-row class="pa-0 mt-4">
        <v-col cols="12" md="4" lg="4">
          <CardRelatorio />
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <CardRealizado />
        </v-col>
        <v-col
          class="d-flex flex-column justify-space-between"
          cols="12"
          md="4"
          lg="4"
        >
          <CardComissao class="mb-6" />
          <CardMeta />
        </v-col>
      </v-row>
    </v-container>

    <FilterDashboard @selectedFilters="syncFilters($event)" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import BaseInformationDashboard from "@/components/shared/BaseInformationDashboard";
import FilterDashboard from "./FilterDashboard.vue";
import currencyMixin from "@/mixins/currencyMixin";

export default {
  name: "Dashboard",
  mixins: [currencyMixin],
  components: {
    ResultadoPorMes: () =>
      import(
        "@/components/campanha/dashboard/empresa/charts/ResultadoPorMesEmpresa"
      ),
    BaseInformationDashboard,
    FilterDashboard,
    CardComissao: () => import("./charts/CardComissao"),
    CardMeta: () => import("./charts/CardMeta"),
    CardRelatorio: () => import("./charts/CardRelatorio"),
    CardRealizado: () => import("./charts/CardRealizado")
    // ValorBonusPagoPorMes: () =>
    //   import(
    //     "@/components/campanha/dashboard/empresa/charts/ValorBonusPagoPorMes"
    //   ),
    // BonusPago: () =>
    //   import("@/components/campanha/dashboard/campanha/charts/BonusPago"),
    // MediaBonusPorPeriodo: () =>
    //   import(
    //     "@/components/campanha/dashboard/empresa/charts/MediaBonusPorPeriodo"
    //   )
  },

  computed: {
    ...mapGetters({
      getHiddenValue: "sistema/getHiddenValue"
    })
  }
};
</script>

<style scoped>
.container-chart {
  margin-top: -90px;
}

.container-card {
  margin-top: -60px;
  padding: 12px 30px;
}

.config-container {
  background-color: #f5f5f5;
}

.divider-card {
  margin: 30px 0;
  border: 1px solid #e4e4e4;
}
</style>
