<template>
  <v-container class="config-container pa-0">
    <v-container class="container-chart pa-0">
      <ResultadoPorMes :colaborador="filters.id_colaborador || colaborador" />
    </v-container>
    <v-container class="container-card">
      <v-card class="d-flex justify-space-around">
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="hasValue(vendas.novos) | BrazilianCurrency"
          :hiddenValue="getHiddenValue"
          label="NOVOS"
        />
        <v-divider vertical class="divider-card"></v-divider>
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="hasValue(vendas.usados) | BrazilianCurrency"
          :hiddenValue="getHiddenValue"
          label="USADOS"
        />
        <v-divider vertical class="divider-card"></v-divider>
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="hasValue(vendas.p1) | BrazilianCurrency"
          :hiddenValue="getHiddenValue"
          label="P1"
        />
        <v-divider vertical class="divider-card"></v-divider>
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="hasValue(vendas.p2) | BrazilianCurrency"
          :hiddenValue="getHiddenValue"
          label="P2"
        />
      </v-card>
      <v-row class="pa-0 mt-4">
        <v-col cols="12" md="4" lg="4">
          <CardRelatorio :resumoVendas="info" :hiddenValue="getHiddenValue" />
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <CardRealizado :resumoVendas="info" />
        </v-col>
        <v-col
          class="d-flex flex-column justify-space-between"
          cols="12"
          md="4"
          lg="4"
        >
          <CardComissao
            class="mb-6"
            :comissao="comissao"
            :bonus="bonus"
            :receita="comissao + bonus"
            :hiddenValue="getHiddenValue"
          />
          <CardMeta :metas="metas" />
        </v-col>
      </v-row>
    </v-container>

    <FilterDashboard @selectedFilters="syncFilters($event)" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import comissao from "@/services/http/comissaoService";
import BaseInformationDashboard from "@/components/shared/BaseInformationDashboard";
import FilterDashboard from "./FilterDashboard.vue";
import currencyMixin from "@/mixins/currencyMixin";

export default {
  name: "Dashboard",

  mixins: [currencyMixin],

  components: {
    ResultadoPorMes: () => import("./charts/ResultadoPorMes"),
    BaseInformationDashboard,
    FilterDashboard,
    CardComissao: () => import("./charts/CardComissao"),
    CardMeta: () => import("./charts/CardMeta"),
    CardRelatorio: () => import("./charts/CardRelatorio"),
    CardRealizado: () => import("./charts/CardRealizado")
  },

  props: {
    colaborador: {
      type: String
    }
  },

  data() {
    return {
      filters: {},
      info: [],
      metas: {},
      vendas: {},
      comissao: 0,
      bonus: 0
    };
  },

  computed: {
    ...mapGetters({
      getHiddenValue: "sistema/getHiddenValue"
    })
  },

  methods: {
    async fetchMetas() {
      let { data } = await comissao()
        .metaIndividual()
        .show({
          per_page: -1,
          id_colaborador: this.filters.id_colaborador || this.colaborador,
          id_indicador: "11110002,11110001,11110006",
          id_tipo_evento: "C",
          id_tipo_dado: "R",
          id_empresa: this.filters.id_empresa,
          ano_ref: this.filters.ano_ref,
          mes_ref: this.filters.mes_ref
        });

      this.metas = this.groupByMetas(data.data);
    },

    groupByMetas(array) {
      return array.reduce((result, currentValue) => {
        if (currentValue.id_indicador == "11110001")
          result["novos"] = result["novos"] || currentValue.meta_qtde;
        if (currentValue.id_indicador == "11110002")
          result["consorcio"] = result["consorcio"] || currentValue.meta_qtde;
        if (currentValue.id_indicador == "11110006")
          result["usados"] = result["usados"] || currentValue.meta_qtde;
        return result;
      }, {});
    },

    async fetchResumoVendedor() {
      let { data } = await comissao()
        .resumoVendedor()
        .show({
          per_page: -1,
          id_colaborador: this.filters.id_colaborador || this.colaborador,
          id_empresa: this.filters.id_empresa,
          ano_ref: this.filters.ano_ref,
          mes_ref: this.filters.mes_ref
        });
      this.info = data.data;
      this.vendas = this.groupByVendas(data.data);
      this.valueComissao(Object.values(this.vendas));
    },

    groupByVendas(array) {
      return array.reduce((result, currentValue) => {
        if (currentValue.novo_usado == "N") {
          result["novos"] =
            result["novos"] + parseFloat(currentValue.total_comissao) ||
            parseFloat(currentValue.total_comissao);
        }

        if (currentValue.novo_usado == "U") {
          result["usados"] =
            result["usados"] + parseFloat(currentValue.total_comissao) ||
            parseFloat(currentValue.total_comissao);
        }

        if (currentValue.status == "P1") {
          result["p1"] =
            result["p1"] + parseFloat(currentValue.total_comissao) ||
            parseFloat(currentValue.total_comissao);
        }

        if (currentValue.status == "P2") {
          result["p2"] =
            result["p2"] + parseFloat(currentValue.total_comissao) ||
            parseFloat(currentValue.total_comissao);
        }
        return result;
      }, {});
    },

    valueComissao(array) {
      this.comissao = 0;
      array.map(item => {
        this.comissao += item;
      });
    },

    async fetchResumoVeiculo() {
      let { data } = await comissao()
        .resumoVeiculo()
        .show({
          per_page: -1,
          id_colaborador: this.filters.id_colaborador || this.colaborador,
          id_empresa: this.filters.id_empresa,
          ano_ref: this.filters.ano_ref,
          mes_ref: this.filters.mes_ref
        });
      this.info = data.data;
      this.bonus = 0;
      data.data.map(item => {
        this.bonus += parseFloat(item.total_comissao);
      });
    },

    hasValue(value) {
      if (value == undefined) {
        return 0;
      }
      return value;
    },

    async syncFilters(filter) {
      this.filters = filter;
      this.fetchMetas();
      this.fetchResumoVendedor();
      this.fetchResumoVeiculo();
    }
  },

  mounted() {
    const date = new Date().toISOString().substr(0, 7);
    const [year, month] = date.split("-");
    const filter = {
      ano_ref: parseInt(year),
      mes_ref: parseInt(month)
    };
    this.syncFilters(filter);
  }
};
</script>

<style scoped>
.container-chart {
  margin-top: -90px;
}

.container-card {
  margin-top: -60px;
  padding: 12px 30px;
}

.config-container {
  background-color: #f5f5f5;
}

.divider-card {
  margin: 30px 0;
  border: 1px solid #e4e4e4;
}
</style>
