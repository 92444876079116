<template>
  <div>
    <component :is="selectedComponent" :colaborador="idColaborador"></component>
  </div>
</template>

<script>
import sistema from "@/services/http/sistemaService";
import { mapActions, mapGetters } from "vuex";
import DashboardDiretoria from "@/components/comissao/dashboard/DashboardDiretoria";
import DashboardGerente from "@/components/comissao/dashboard/DashboardGerente";
import DashboardSupervisor from "@/components/comissao/dashboard/DashboardSupervisor";
import DashboardColaborador from "@/components/comissao/dashboard/DashboardColaborador";
import rolesDashboard from "@/utils/rolesDashboard";

export default {
  name: "Dashboard",
  components: {
    DashboardDiretoria,
    DashboardGerente,
    DashboardSupervisor,
    DashboardColaborador
  },

  data() {
    return {
      idColaborador: "",
      selectedComponent: ""
    };
  },

  computed: {
    ...mapGetters({
      getUser: "getUser"
    })
  },
  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    }),

    async init() {
      let {
        data: { data }
      } = await sistema()
        .usuario()
        .show({ id_usuario: this.getUser.id });
      this.idColaborador = data.map(item => item.id_colaborador).toString();
      let _component = rolesDashboard.currentDashboard;
      this.selectedComponent = () =>
        import(`@/components/comissao/dashboard/${_component}`);
    }
  },

  async mounted() {
    this.fetchView("Dashboard Comissão");
    await this.init();
  }
};
</script>

<style></style>
