var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-box "},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"320","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""}},on),[_c('v-icon',[_vm._v("mdi-tune-variant")])],1)]}}])},[_c('v-card',[_c('v-container',[_c('BaseFilter',{attrs:{"prepend-inner-icon":"mdi-file-table-box-multiple-outline","clearable":"","single-line":"","label":"Segmento","item-text":"descricao","item-value":"id_band","service":"sistemaService.tipoBandeiras","filters":{ id_band: '2,4' },"isCompany":true},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_band),callback:function ($$v) {_vm.$set(_vm.filters, "id_band", $$v)},expression:"filters.id_band"}}),_c('BaseFilter',{attrs:{"prepend-inner-icon":"mdi-domain","clearable":"","single-line":"","label":"Empresa","item-text":"apelido","item-value":"id_empresa","service":"sistemaService.empresa","filters":{
            id_band: _vm.filters.id_band,
            calcula_comissao: 'S'
          },"isCompany":true},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_empresa),callback:function ($$v) {_vm.$set(_vm.filters, "id_empresa", $$v)},expression:"filters.id_empresa"}}),_c('BaseFilter',{directives:[{name:"can-access",rawName:"v-can-access",value:(1),expression:"1"}],attrs:{"prepend-inner-icon":"mdi-account","clearable":"","single-line":"","label":"Colaboradores","item-text":"colaborador","item-value":"id_colaborador","service":"sistemaService.colaboradorEmpresa","filters":{
            id_empresa: _vm.filters.id_empresa,
            id_band: _vm.filters.id_band,
            ativo: 'S'
          }},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_colaborador),callback:function ($$v) {_vm.$set(_vm.filters, "id_colaborador", $$v)},expression:"filters.id_colaborador"}}),_c('BaseFilter',{attrs:{"label":"Equipe","item-text":"nome_equipe","single-line":"","item-value":"id_equipe","clearable":"","prepend-inner-icon":"mdi-account-multiple","service":"comissaoService.tipoEquipe","filters":{
            id_band: _vm.filters.id_band,
            id_empresa: _vm.filters.id_empresa,
            ativo: 'S'
          }},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_equipe),callback:function ($$v) {_vm.$set(_vm.filters, "id_equipe", $$v)},expression:"filters.id_equipe"}}),_c('DatePickerMonth',{attrs:{"chips":"","currentDate":true},on:{"date":function($event){return _vm.assignDateFilter($event)}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }