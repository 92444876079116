<template>
  <div class="filter-box ">
    <v-menu :close-on-content-click="false" max-width="320" offset-x>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" color="white" icon>
          <v-icon>mdi-tune-variant</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-container>
          <BaseFilter
            prepend-inner-icon="mdi-file-table-box-multiple-outline"
            clearable
            single-line
            label="Segmento"
            item-text="descricao"
            item-value="id_band"
            service="sistemaService.tipoBandeiras"
            v-model="filters.id_band"
            :filters="{ id_band: '2,4' }"
            :isCompany="true"
            @change="handleFilter()"
          />

          <BaseFilter
            prepend-inner-icon="mdi-domain"
            clearable
            single-line
            label="Empresa"
            item-text="apelido"
            item-value="id_empresa"
            service="sistemaService.empresa"
            :filters="{
              id_band: filters.id_band,
              calcula_comissao: 'S'
            }"
            v-model="filters.id_empresa"
            :isCompany="true"
            @change="handleFilter()"
          />

          <BaseFilter
            v-can-access="1"
            prepend-inner-icon="mdi-account"
            clearable
            single-line
            label="Colaboradores"
            item-text="colaborador"
            item-value="id_colaborador"
            service="sistemaService.colaboradorEmpresa"
            :filters="{
              id_empresa: filters.id_empresa,
              id_band: filters.id_band,
              ativo: 'S'
            }"
            v-model="filters.id_colaborador"
            @change="handleFilter()"
          />

          <BaseFilter
            v-model="filters.id_equipe"
            label="Equipe"
            item-text="nome_equipe"
            single-line
            item-value="id_equipe"
            clearable
            prepend-inner-icon="mdi-account-multiple"
            service="comissaoService.tipoEquipe"
            :filters="{
              id_band: filters.id_band,
              id_empresa: filters.id_empresa,
              ativo: 'S'
            }"
            @change="handleFilter()"
          />

          <DatePickerMonth
            chips
            :currentDate="true"
            @date="assignDateFilter($event)"
          />
        </v-container>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import BaseFilter from "@/components/shared/BaseSelectService";
import DatePickerMonth from "@/components/shared/DatePickerMonth";

export default {
  name: "FilterDashboard",
  components: { BaseFilter, DatePickerMonth },
  data() {
    return {
      filters: {}
    };
  },

  methods: {
    handleFilter() {
      this.$emit("selectedFilters", this.filters);
    },
    assignDateFilter(date) {
      this.filters["ano_ref"] = date?.ano_ref;
      this.filters["mes_ref"] = date?.mes_ref;
      this.handleFilter();
    }
  }
};
</script>
<style scoped>
.filter-box {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 20%;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px 0 0 8px;
  text-align: center;
  cursor: pointer;
}
</style>
