<template>
  <v-container class="config-container pa-0">
    <v-container class="container-chart pa-0">
      <ResultadoPorMesSup />
    </v-container>
    <v-container class="container-card">
      <v-card class="d-flex justify-space-around">
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="hasValue(valores.comissao) | BrazilianCurrency"
          :hiddenValue="getHiddenValue"
          label="COMISSÃO"
        />
        <v-divider vertical class="divider-card"></v-divider>
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="hasValue(valores.bonus) | BrazilianCurrency"
          :hiddenValue="getHiddenValue"
          label="BÔNUS"
        />
        <v-divider vertical class="divider-card"></v-divider>
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="hasValue(valores.possivel) | BrazilianCurrency"
          :hiddenValue="getHiddenValue"
          label="POSSÍVEL"
        />
        <v-divider vertical class="divider-card"></v-divider>
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="hasValue(valores.perdido) | BrazilianCurrency"
          :hiddenValue="getHiddenValue"
          label="PERDIDO"
        />
      </v-card>
      <v-row class="pa-0 mt-4">
        <v-col cols="12" md="4" lg="4">
          <CardRelatorio :dados="metas" :title="'Metas'" />
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <CardRelatorio :dados="realizado" :title="'Realizado'" />
        </v-col>

        <v-col
          class="d-flex flex-column justify-space-between"
          cols="12"
          md="4"
          lg="4"
        >
          <CardComissao
            class="mb-6"
            :receita="hasValue(valores.receita)"
            :hiddenValue="getHiddenValue"
          />
          <CardRanking :metas="metas" />
        </v-col>
      </v-row>
    </v-container>

    <FilterDashboard @selectedFilters="syncFilters($event)" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import BaseInformationDashboard from "@/components/shared/BaseInformationDashboard";
import FilterDashboard from "./FilterDashboard.vue";
import comissao from "@/services/http/comissaoService";
import currencyMixin from "@/mixins/currencyMixin";

export default {
  name: "DashboardGerente",
  mixins: [currencyMixin],
  components: {
    ResultadoPorMesSup: () => import("./charts/gerente/ResultadoPorMes"),
    BaseInformationDashboard,
    FilterDashboard,
    CardComissao: () => import("./charts/CardComissao"),
    CardRanking: () => import("./charts/gerente/CardRanking"),
    CardRelatorio: () => import("./charts/gerente/CardRelatorio")
  },
  props: {
    colaborador: {
      type: String
    }
  },
  data() {
    return {
      filters: {},
      valores: {},
      metas: [],
      realizado: []
    };
  },

  computed: {
    ...mapGetters({
      getHiddenValue: "sistema/getHiddenValue"
    })
  },

  methods: {
    async fetchComissao() {
      let { data } = await comissao().show({
        per_page: -1,
        id_colaborador: this.filters.id_colaborador || this.colaborador,
        id_empresa: this.filters.id_empresa,
        ano_ref: this.filters.ano_ref,
        mes_ref: this.filters.mes_ref
      });

      this.valores.salario = this.getSalario(data.data);
      this.valores.bonus = this.getBonus(data.data);
      this.valores.comissao = this.getComissao(data.data);
      this.valores.perdido = this.getPerdigo(data.data);
      this.valores.possivel =
        this.getSalario(data.data) +
        this.getComissao(data.data) +
        this.getBonus(data.data);
      this.valores.receita =
        this.getSalario(data.data) +
        this.getComissao(data.data) +
        this.getBonus(data.data) -
        this.getPerdigo(data.data);
    },
    getSalario(arr) {
      let salario = 0;
      arr.map(item => {
        if (item.valor_com != null && item.id_tipo_classe == "S") {
          return (salario = parseFloat(item.valor_base));
        }
      });

      return salario;
    },

    getBonus(arr) {
      let bonus = 0;
      arr.map(item => {
        if (item.valor_com != null && item.id_tipo_classe == "B") {
          return (bonus += parseFloat(item.valor_com));
        }
      });

      return bonus;
    },

    getComissao(arr) {
      let comissao = 0;
      arr.map(item => {
        if (item.valor_com != null && item.id_tipo_classe == "C") {
          return (comissao += parseFloat(item.valor_com));
        }
      });

      return comissao;
    },

    getPerdigo(arr) {
      let perdido = 0;
      arr.map(item => {
        if (item.valor_com != null) {
          return (perdido += parseFloat(item.valor_perdido));
        }
      });
      return perdido;
    },
    async fetchMetaEmpresa() {
      let { data } = await comissao()
        .metaEmpresa()
        .show({
          per_page: -1,
          id_tipo_evento: "C",
          id_tipo_dado: "R",
          id_empresa: this.filters.id_empresa,
          ano_ref: this.filters.ano_ref,
          mes_ref: this.filters.mes_ref
        });
      this.metas = data.data;
    },

    async fetchRealizadoEmpresas() {
      let { data } = await comissao()
        .realizadoEmpresa()
        .show({
          per_page: -1,
          id_tipo_evento: "C",
          id_tipo_dado: "R",
          id_empresa: this.filters.id_empresa,
          ano_ref: this.filters.ano_ref,
          mes_ref: this.filters.mes_ref
        });

      this.realizado = data.data;
    },

    hasValue(value) {
      if (value == undefined) {
        return 0;
      }
      return value;
    },

    async syncFilters(filter) {
      this.filters = filter;
      this.fetchComissao();
      this.fetchMetaEmpresa();
      this.fetchRealizadoEmpresas();
    }
  },

  mounted() {
    const date = new Date().toISOString().substr(0, 7);
    const [year, month] = date.split("-");
    const filter = {
      ano_ref: parseInt(year),
      mes_ref: parseInt(month)
    };
    this.syncFilters(filter);
  }
};
</script>

<style scoped>
.container-chart {
  margin-top: -90px;
}

.container-card {
  margin-top: -60px;
  padding: 12px 30px;
}

.config-container {
  background-color: #f5f5f5;
}

.divider-card {
  margin: 30px 0;
  border: 1px solid #e4e4e4;
}
</style>
