<template>
  <v-container class="config-container pa-0">
    <v-container class="container-chart pa-0">
      <ResultadoPorMesSup />
    </v-container>
    <v-container class="container-card">
      <v-card class="d-flex justify-space-around">
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="hasValue(valores.comissao) | BrazilianCurrency"
          :hiddenValue="getHiddenValue"
          label="COMISSÃO"
        />
        <v-divider vertical class="divider-card"></v-divider>
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="hasValue(valores.bonus) | BrazilianCurrency"
          :hiddenValue="getHiddenValue"
          label="BÔNUS"
        />
        <v-divider vertical class="divider-card"></v-divider>
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="hasValue(valores.possivel) | BrazilianCurrency"
          :hiddenValue="getHiddenValue"
          label="POSSÍVEL"
        />
        <v-divider vertical class="divider-card"></v-divider>
        <BaseInformationDashboard
          icon="mdi-cash-multiple"
          color="success"
          :value="hasValue(valores.perdido) | BrazilianCurrency"
          :hiddenValue="getHiddenValue"
          label="PERDIDO"
        />
      </v-card>
      <v-row class="pa-0 mt-4">
        <!-- <v-col cols="12" md="4" lg="4">
          <CardRelatorio />
        </v-col> -->
        <v-col cols="12" md="8" lg="8">
          <CardRealizadoSup :resumoVendas="resumoEquipe" />
        </v-col>
        <v-col
          class="d-flex flex-column justify-space-between"
          cols="12"
          md="4"
          lg="4"
        >
          <CardComissao
            class="mb-6"
            :receita="hasValue(valores.receita)"
            :hiddenValue="getHiddenValue"
          />
          <CardMeta :metas="metas" />
        </v-col>
      </v-row>
    </v-container>

    <FilterDashboard @selectedFilters="syncFilters($event)" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import BaseInformationDashboard from "@/components/shared/BaseInformationDashboard";
import FilterDashboard from "./FilterDashboard.vue";
import comissao from "@/services/http/comissaoService";
import currencyMixin from "@/mixins/currencyMixin";

export default {
  name: "Dashboard",
  mixins: [currencyMixin],
  components: {
    ResultadoPorMesSup: () => import("./charts/supervisor/ResultadoPorMesSup"),
    BaseInformationDashboard,
    FilterDashboard,
    CardComissao: () => import("./charts/CardComissao"),
    CardMeta: () => import("./charts/supervisor/CardMeta.vue"),
    CardRealizadoSup: () => import("./charts/supervisor/CardRealizadoSup.vue")
  },

  props: {
    colaborador: {
      type: String
    }
  },

  data() {
    return {
      info: [],
      resumoEquipe: [],
      filters: {},
      valores: {},
      metas: {}
    };
  },

  computed: {
    ...mapGetters({
      getHiddenValue: "sistema/getHiddenValue"
    })
  },

  methods: {
    async fetchComissaoEquipe() {
      let { data } = await comissao()
        .comissaoEquipe()
        .show({
          per_page: -1,
          id_colaborador: this.filters.id_colaborador || this.colaborador,
          id_empresa: this.filters.id_empresa,
          ano_ref: this.filters.ano_ref,
          mes_ref: this.filters.mes_ref
        });

      this.info = data.data;

      this.valores.salario = this.getSalario(this.info);
      this.valores.bonus = this.getBonus(this.info);
      this.valores.comissao = this.getComissao(this.info);
      this.valores.perdido = this.getPerdigo(this.info);
      this.valores.possivel =
        this.getSalario(this.info) +
        this.getComissao(this.info) +
        this.getBonus(this.info);
      this.valores.receita =
        this.getSalario(this.info) +
        this.getComissao(this.info) +
        this.getBonus(this.info) -
        this.getPerdigo(this.info);
    },

    async fetchResumoEquipe() {
      let { data } = await comissao()
        .resumoEquipe()
        .show({
          per_page: -1,
          id_colaborador: this.filters.id_colaborador || this.colaborador,
          id_tipo_evento: "C",
          id_tipo_dado: "R",
          cod_funcao: "000018",
          id_empresa: this.filters.id_empresa,
          ano_ref: this.filters.ano_ref,
          mes_ref: this.filters.mes_ref
        });

      this.resumoEquipe = data.data;
    },

    getSalario(arr) {
      let salario = 0;
      arr.map(item => {
        if (item.valor_com != null && item.id_tipo_classe == "S") {
          return (salario = parseFloat(item.valor_base));
        }
      });

      return salario;
    },

    getBonus(arr) {
      let bonus = 0;
      arr.map(item => {
        if (item.valor_com != null && item.id_tipo_classe == "B") {
          return (bonus += parseFloat(item.valor_com));
        }
      });

      return bonus;
    },

    getComissao(arr) {
      let comissao = 0;
      arr.map(item => {
        if (item.valor_com != null && item.id_tipo_classe == "C") {
          return (comissao += parseFloat(item.valor_com));
        }
      });

      return comissao;
    },

    getPerdigo(arr) {
      let perdido = 0;
      arr.map(item => {
        if (item.valor_com != null) {
          return (perdido += parseFloat(item.valor_perdido));
        }
      });
      return perdido;
    },

    async fetchMetas() {
      let { data } = await comissao()
        .metaEquipe()
        .show({
          per_page: -1,
          id_indicador: "11110002,11110001,11110006, 11110011",
          id_tipo_evento: "C",
          id_tipo_dado: "R",
          id_equipe: this.filters.id_equipe,
          ano_ref: this.filters.ano_ref,
          mes_ref: this.filters.mes_ref
        });

      this.metas = this.groupByMetas(data.data);
    },

    groupByMetas(array) {
      return array.reduce((result, currentValue) => {
        if (currentValue.id_indicador == "11110001")
          result["novos"] = result["novos"] || currentValue.meta_qtde;
        if (currentValue.id_indicador == "11110002")
          result["consorcio"] = result["consorcio"] || currentValue.meta_qtde;
        if (currentValue.id_indicador == "11110006")
          result["usados"] = result["usados"] || currentValue.meta_qtde;
        if (currentValue.id_indicador == "11110011")
          result["media"] = result["media"] || currentValue.meta_qtde;
        return result;
      }, {});
    },

    hasValue(value) {
      if (value == undefined) {
        return 0;
      }
      return value;
    },

    async syncFilters(filter) {
      this.filters = filter;
      this.fetchComissaoEquipe();
      this.fetchResumoEquipe();
    }
  },

  mounted() {
    const date = new Date().toISOString().substr(0, 7);
    const [year, month] = date.split("-");
    const filter = {
      ano_ref: parseInt(year),
      mes_ref: parseInt(month)
    };
    this.syncFilters(filter);
  }
};
</script>

<style scoped>
.container-chart {
  margin-top: -90px;
}

.container-card {
  margin-top: -60px;
  padding: 12px 30px;
}

.config-container {
  background-color: #f5f5f5;
}

.divider-card {
  margin: 30px 0;
  border: 1px solid #e4e4e4;
}
</style>
